var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "content" }, [
    _c("div", { staticClass: "description" }, [
      _c("table", { staticClass: "table" }, [
        _vm._m(0),
        _c(
          "tbody",
          { staticClass: "table-body" },
          _vm._l(_vm.mapRow, function (row, index) {
            return _c("tr", { key: index, staticClass: "body-row" }, [
              _c("td", { staticClass: "body-cellule text-center" }, [
                _c("strong", [_vm._v(_vm._s(row.label))]),
              ]),
              _c("td", { staticClass: "body-cellule" }, [
                _vm._v(_vm._s(row.dsn)),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "grp-btn" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-front",
            attrs: { variant: "primary" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.onStartFront()
              },
            },
          },
          [_vm._v("Front")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "table-head" }, [
      _c("tr", { staticClass: "header-row" }, [
        _c("th", { staticClass: "header-cellule" }, [_vm._v("*")]),
        _c("th", { staticClass: "header-cellule" }, [_vm._v("DSN")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }