<template>
	<section class="content">
		<div class="description">
			<table class="table">
				<thead class="table-head">
					<tr class="header-row">
						<th class="header-cellule">*</th>
						<th class="header-cellule">DSN</th>
					</tr>
				</thead>
				<tbody class="table-body">
					<tr class="body-row" v-for="(row, index) of mapRow" :key="index">
						<td class="body-cellule text-center">
							<strong>{{ row.label }}</strong>
						</td>
						<td class="body-cellule">{{ row.dsn }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="grp-btn">
			<b-button variant="primary" class="btn-front" @click.prevent.stop="onStartFront()">Front</b-button>
		</div>
	</section>
</template>
<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import { SENTRY_LABEL_MAINTENANCE } from '@/shared/constants';
import { getProjectUsersTsDaysPlanning } from '@/cruds/timesheets-efc.crud';
import './maintenance.scss';
export default {
	name: 'MaintenanceFimalac',
	data: () => ({
		start: false
	}),
	mixins: [globalMixin, languageMessages],
	computed: {
		mapRow() {
			const { VUE_APP_SENTRY_DSN, VUE_APP_SENTRY_DSN_BACK } = process.env;
			return [
				{ label: 'Front', dsn: VUE_APP_SENTRY_DSN || '' }
				// { label: 'Back', dsn: VUE_APP_SENTRY_DSN_BACK || '' }
			];
		}
	},
	methods: {
		onStartBack() {
			this.createToastForMobile(
				this.FormMSG(1, 'Information sentry Back'),
				this.FormMSG(2, 'Error checking for backend has been enabled, check sentry for backend'),
				'',
				'info'
			);
			return getProjectUsersTsDaysPlanning({ devOption: 'test' }, '');
		},
		onStartFront() {
			const { FRONT } = SENTRY_LABEL_MAINTENANCE;
			throw new Error(FRONT);
		}
	}
};
</script>
